import React from 'react'
interface Props {
  className?: string
  version?: string
  title?: string
  icon?: React.ReactNode
}
export const Version = ({
  className = '',
  version = '0.0',
  title = '',
  icon = <i className="icon-book " />,
}: Props) => {
  return (
    <div
      data-cy="Version"
      className={`fixed bottom-0 right-0 bg-gray-light text-gray-dark py-1.5
    px-3 rounded-tl-lg text-xs flex justify-between items-center tracking-wide  opacity-80
     ${className}`}
      style={{fontSize: '.7rem'}}
      title={title}
    >
      <span className="mr-1.5">{icon}</span>v{version}
    </div>
  )
}
