import React from 'react'
import {Link} from 'gatsby'
import {useTranslation} from 'react-i18next'
import logo from 'assets/images/evet.svg'
import {SubscribeForm} from './SubscribeForm'

export const Footer = ({siteTitle}) => {
  const {t} = useTranslation()
  return (
    <footer className="bg-stone-50 rounded-xl md:mt-8 md:p-8 p-4 text-center">
      <div className="h-full mx-auto container flex flex-col">
        <div className="w-1/2 mx-auto flex md:flex-row flex-col items-center justify-around">
          <Link to="/about" className="px-4 py-3 text-stone-400">
            {t('About Us')}
          </Link>
          <Link to="/team" className="px-4 py-3 text-stone-400">
            {t('Our Team')}
          </Link>
          <Link
            to="/pricing"
            className="px-4 py-3 text-stone-400"
          >
            {t('Pricing')}
          </Link>
          <a
            href="https://bridge.evet.com/"
            className="px-4 py-3 text-stone-400"
          >
            {t('Bridge')}
          </a>
        </div>

        <div
          className="h-full flex flex-col items-center justify-center
        text-stone-400 mt-4"
        >
          <Link to="/" className="p-4">
            <img src={logo} alt={siteTitle} />
          </Link>
          <address className="not-italic">
            <a
              href="mailto:compliance@evet.com"
              className="block text-gray-500 p-2"
            >
              compliance@evet.com
            </a>
            <p className="px-8 md:py-0 py-2">
              Evet Technologies Inc. 11767 S Dixie Hwy, #233,
              Pinecrest, FL, 33156
            </p>
          </address>
          <div className="my-6 w-full flex items-center justify-center">
            <SubscribeForm label={t('Join our mailing list')} />
          </div>
          <p className="px-8">
            {t(
              'Subscribe today to get the latest ecommerce trend and updates on our technology products.'
            )}
          </p>
        </div>

        <div
          className="md:w-1/2 mx-auto flex md:flex-row flex-col items-center
        justify-around mt-6 md:pb-0 pb-10"
        >
          <a
            href={process.env.GATSBY_AUTH + '/privacy-policy'}
            className="px-4 text-stone-400"
          >
            {t('Privacy Policy')}
          </a>
          <a
            href={process.env.GATSBY_AUTH + '/terms-of-services'}
            className="px-4 text-stone-400"
          >
            {t('Terms of Services')}
          </a>
        </div>
      </div>
    </footer>
  )
}
