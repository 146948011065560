import React from 'react'
import {useTranslation} from 'react-i18next'
interface Props {
  label?: string
}
export const SubscribeForm = ({label}: Props) => {
  const {t} = useTranslation()
  return (
    <form className=" md:w-auto w-full flex md:flex-row flex-col items-center md:mx-8 mx-4">
      {label && (
        <label
          className="md:pr-8 font-semibold text-left uppercase text-gray-900
        md:pb-0 pb-4"
        >
          {label}
        </label>
      )}
      <input
        className="
            py-3 px-5 md:w-96 w-full
            md:text-lg text-gray-400 bg-white shadow border rounded-md
            "
        type="email"
        name="email"
        placeholder={t('Enter your e-mail address')}
      />
      <button
        type="submit"
        className="md:ml-2 py-4 px-6 bg-green-500 rounded-md
      w-full md:w-auto md:mt-0 mt-4
      flex items-center justify-center
      "
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 relative"
          preserveAspectRatio="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2929 5.29289C12.6834 4.90237 13.3166 4.90237 13.7071 5.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L13.7071 14.7071C13.3166 15.0976 12.6834 15.0976 12.2929 14.7071C11.9024 14.3166 11.9024 13.6834 12.2929 13.2929L14.5858 11H3C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L12.2929 6.70711C11.9024 6.31658 11.9024 5.68342 12.2929 5.29289Z"
            fill="white"
          />
        </svg>
      </button>
    </form>
  )
}
