import React, {useState} from 'react'
import {Link} from 'gatsby'
import {useTranslation} from 'react-i18next'
import logo from 'assets/images/evet.svg'

export const CustomHeader = ({siteTitle}) => {
  const {t} = useTranslation()
  console.log(process.env)
  const [show, setShow] = useState(false)
  const toggleMenu = () => {
    setShow(!show)
  }
  return (
    <>
      <header
        className="text-base
      leading-6 font-semibold tracking-wider uppercase
      md:h-30 h-16 md:bg-transparent bg-white
      xl:px-0 px-4"
      >
        <div className="h-full mx-auto container flex flex-row items-center justify-between">
          <h1 className="h-full flex items-center justify-center">
            <Link to="/">
              <img
                src={logo}
                alt={siteTitle}
                className="md:h-10 h-8"
              />
            </Link>
          </h1>
          <div className="h-full items-center justify-end hidden md:flex">
            <Link to="/pricing" className="px-4">
              {t('PRICING')}
            </Link>
            <a href={process.env.GATSBY_AUTH} className="px-4">
              {t('SIGN IN')}
            </a>
            <a
              href={process.env.GATSBY_AUTH + '/register/guard'}
              className="ml-4 bg-green-500 text-white rounded shadow-sm px-4 py-1"
            >
              {t('SIGN UP FOR FREE')}
            </a>
          </div>
          <div className="h-full flex items-center justify-end md:hidden">
            <button onClick={toggleMenu}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 relative"
                preserveAspectRatio="none"
              >
                <rect
                  width="24"
                  height="24"
                  rx="6"
                  fill="#FCD34D"
                />
                <path
                  d="M5 7C5 6.44772 5.44772 6 6 6H18C18.5523 6 19 6.44772 19 7C19 7.55228 18.5523 8 18 8H6C5.44772 8 5 7.55228 5 7Z"
                  fill="#F9FAFB"
                />
                <path
                  d="M5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6C5.44772 13 5 12.5523 5 12Z"
                  fill="#F9FAFB"
                />
                <path
                  d="M5 17C5 16.4477 5.44772 16 6 16H18C18.5523 16 19 16.4477 19 17C19 17.5523 18.5523 18 18 18H6C5.44772 18 5 17.5523 5 17Z"
                  fill="#F9FAFB"
                />
              </svg>
            </button>
          </div>
        </div>
      </header>
      {show && (
        <div
          className="absolute top-16 border-t-2 border-stone-50
      w-full bg-white shadow-2xl grid z-10"
        >
          <Link
            to="/pricing"
            className="px-4 py-3 border-b-2 border-stone-50 text-sm font-semibold"
          >
            {t('PRICING')}
          </Link>
          <a
            href={process.env.GATSBY_AUTH}
            className="px-4 py-3 text-sm font-semibold"
          >
            {t('SIGN IN')}
          </a>
          <a
            href={process.env.GATSBY_AUTH + '/register/guard'}
            className="m-4 bg-green-500 text-white text-center rounded shadow-sm px-4 py-1"
          >
            {t('SIGN UP FOR FREE')}
          </a>
        </div>
      )}
    </>
  )
}
