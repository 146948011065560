import React from 'react'
import {Helmet} from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'

type props = {
  lang?: string
  title: string
  description: string
  meta?:
    | [{name?: string; property?: string; content: string}]
    | []
}

export const SEO = ({
  description,
  lang,
  meta = [],
  title,
}: props) => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            titleTemplate
            description
            author
          }
        }
      }
    `
  )

  const titleTemplate = site.siteMetadata?.titleTemplate || `%s`
  const defaultTitle = site.siteMetadata?.title
  title = title || defaultTitle
  description = description || site.siteMetadata.description
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        // @ts-ignore
      ].concat(meta)}
    />
  )
}
