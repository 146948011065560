import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import {CustomHeader, Footer, Version} from 'components'
import {SEO} from './SEO'
// @ts-ignore
import packageJSON from '../../package.json'
const {dependencies, version} = packageJSON

type props = {
  lang?: string
  title: string
  description: string
  meta?: [{name?: string; property?: string; content: string}]
  className?: string
  children: React.ReactNode
  mainClassName?: string
}

export const Layout = ({
  lang,
  title,
  description,
  meta,
  className = '',
  children,
  mainClassName = 'container mx-auto',
}: props): JSX.Element => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={`${className}`}>
      <SEO
        lang={lang || 'en'}
        title={title}
        description={description}
        meta={meta}
      />
      <CustomHeader
        siteTitle={data.site.siteMetadata?.title || `Title`}
      />
      <main className={mainClassName}>{children}</main>
      <div className="container mx-auto bg-white grow-0 md:mb-10">
        <Footer
          siteTitle={data.site.siteMetadata?.title || `Title`}
        />
      </div>
      <Version
        version={version}
        title={
          'evet-library: v' +
          (dependencies['@evet/library'] || '#').split(/#/)[1]
        }
        className="text-gray-300"
      />
    </div>
  )
}
